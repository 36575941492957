import { useTranslation } from 'react-i18next'
import { Navigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

import type { PermissionName, ViewsName } from '@/api/agency/types'
import type { AppData } from '@/contexts/AppContext'
import { useApp } from '@/contexts/AppContext'
import { useUser } from '@/contexts/UserContext'

export default function RequireAgencyPermissions({
    children,
    permission,
    view,
}: {
    children: JSX.Element
    view?: ViewsName
    permission?: PermissionName
}): JSX.Element {
    const { t } = useTranslation()
    const {
        state: { agencyId },
    } = useApp()
    const { isAgencyPermissionAllowed, isAgencyViewAllowed, getHomePath } = useAgencyPermissions({ agencyId })
    const location = useLocation()

    if ((view && !isAgencyViewAllowed(view, true)) || (permission && !isAgencyPermissionAllowed(permission, true))) {
        toast.error(t('error.notFound'))
        return <Navigate to={{ pathname: getHomePath() }} state={{ from: location.state }} />
    }

    return children
}

interface AgencyViewProps {
    agencyId: string | undefined
}

export function useAgencyPermissions(params?: AgencyViewProps) {
    const {
        state: { agencyId, agencies },
    } = useApp()
    const {
        state: { isRoot },
    } = useUser()

    function isAgencyViewAllowed(view: ViewsName, bypassIfRoot = false) {
        if (bypassIfRoot && isRoot) {
            return true
        }
        return canAgencyView(params?.agencyId || agencyId, agencies, view)
    }

    function isAgencyPermissionAllowed(permission: PermissionName, bypassIfRoot = false) {
        if (bypassIfRoot && isRoot) {
            return true
        }
        return canAgencyPermission(params?.agencyId || agencyId, agencies, permission)
    }

    return {
        isAgencyViewAllowed: isAgencyViewAllowed,
        isAgencyPermissionAllowed: isAgencyPermissionAllowed,
        isSaleEnabledFct: (bypassIfRoot?: boolean) => isAgencyViewAllowed('sale_trackingBoard', bypassIfRoot),
        isTransactionEnabledFct: (bypassIfRoot?: boolean) => isAgencyViewAllowed('sale_transactionBoard', bypassIfRoot),
        isAcquisitionEnabledFct: (bypassIfRoot?: boolean) =>
            isAgencyViewAllowed('acquisition_trackingBoard', bypassIfRoot),
        isRentalEnabledFct: (bypassIfRoot?: boolean) => isAgencyViewAllowed('rental_trackingBoard', bypassIfRoot),
        getHomePath: () => {
            return isAgencyViewAllowed('sale_trackingBoard')
                ? '/tracking-board'
                : isAgencyViewAllowed('sale_transactionBoard')
                ? '/transaction/tracking-board'
                : isAgencyViewAllowed('acquisition_trackingBoard')
                ? '/acq/tracking-board'
                : isAgencyViewAllowed('rental_trackingBoard')
                ? '/rental-mgt/tracking-board'
                : '/'
        },
    }
}

export const canAgencyView = (
    agencyId: string | undefined,
    agencies: AppData['agencies'] | undefined,
    view: ViewsName
): boolean | undefined => {
    if (!agencyId) {
        // all agencies
        return agencies
            ? agencies.some((agency) => (agency?.settings?.enableViews?.[view] ?? false) === true)
            : undefined
    } else {
        const agency = agencies?.find((agency) => agency.id === agencyId)
        return agency ? agency?.settings?.enableViews?.[view] ?? false : undefined
    }
}

const canAgencyPermission = (
    agencyId: string | undefined,
    agencies: AppData['agencies'] | undefined,
    permission: PermissionName
): boolean | undefined => {
    if (!agencyId) {
        // all agencies
        return agencies
            ? agencies.some((agency) => (agency?.settings?.permissions[permission] ?? false) === true)
            : undefined
    } else {
        const agency = agencies?.find((agency) => agency.id === agencyId)
        return agency ? agency?.settings?.permissions[permission] ?? false : undefined
    }
}
