import type en from './en.json'
import type fr from './fr.json'

type Translation = typeof fr | typeof en

/** Compare two translation files to check if they are the same */
export const compareTranslationFiles = (a: Translation, b: Translation) => {
    const keysA = Object.keys(a)
    const keysB = Object.keys(b)
    if (keysA.length !== keysB.length) return false

    if (keysA.every((key) => a[key as keyof Translation] === b[key as keyof Translation])) return true

    throw new Error('Translation files are not the same')
}
