import type { Dispatch, ReactNode } from 'react'
import { createContext, useContext, useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'

import { useGetAgency } from '@/api/agency/hooks'
import type { Agency, IResponseAgencies } from '@/api/agency/types'
import { canAgencyView } from '@/components/navbar/RequireAgencyPermissions'
import type { TMediaData } from '@/hooks/useGetMedia'
import useGetMedia, { defaultMediaData } from '@/hooks/useGetMedia'
import useLocalStorage from '@/hooks/useLocalStorage'

import type { AvailableCountryCode, AvailableRegion } from '../i18n'
import { countries, defaultCountryCode } from '../i18n'
import { clearTokens, getAccessToken, getRefreshToken } from '../services/localStorageService'

export interface ConnectionData {
    token: string
    refreshToken: string
}

export interface AppData {
    mediaData: TMediaData | null
    jwt?: ConnectionData
    agencyId?: string
    agency?: Agency
    agencyGroupId?: string
    agencies?: Array<AtLeast<IResponseAgencies, 'id' | 'group' | 'settings' | 'name'>>
    userAgencyIds?: Array<Agency['id']>
    groupAgencyIds?: string | undefined
    countryCode: AvailableCountryCode
    countryRegion: AvailableRegion
    isLoggedIn: boolean
    isDev: boolean
    isDemo: boolean
    isTransactionEnabled?: boolean
    enableViews: Agency['settings']['enableViews']
}

type Action =
    | { type: 'update'; payload: Partial<AppData> }
    | { type: 'login'; payload: Pick<AppData, 'agencyId' | 'jwt'> }
    | { type: 'logout' }

const AppContext = createContext<{ state: AppData; dispatch: React.Dispatch<Action> } | undefined>(undefined)
AppContext.displayName = 'App'

function appReducer(state: AppData, action: Action): AppData {
    switch (action.type) {
        case 'update': {
            if (action.payload?.countryCode) {
                action.payload['countryRegion'] = countries[action.payload.countryCode].region
            }
            return { ...state, ...action.payload }
        }
        case 'login': {
            return { ...state, ...action.payload, isLoggedIn: true }
        }
        case 'logout': {
            clearTokens()
            return { ...state, jwt: undefined, isLoggedIn: false }
        }
        default: {
            return state
        }
    }
}

interface ProviderProps {
    children: ReactNode
}

function AppProvider({ children }: ProviderProps) {
    const { i18n } = useTranslation()
    const mediaData = useGetMedia()

    const [localStore, setLocalStore] = useLocalStorage<AppData>('app-storage', {
        mediaData: { ...defaultMediaData },
        countryCode: defaultCountryCode,
        countryRegion: countries[defaultCountryCode].region,
        agencyId: undefined,
        userAgencyIds: undefined,
        agencies: undefined,
        isLoggedIn: false,
        isDev: import.meta.env.MODE === 'development' || import.meta.env.MODE === 'staging',
        isDemo: import.meta.env.MODE === 'demo',
        isTransactionEnabled: false,
        enableViews: {} as Agency['settings']['enableViews'],
    })
    const initialApp: AppData = {
        mediaData: localStore?.mediaData ?? { ...defaultMediaData },
        countryCode: localStore?.countryCode ?? defaultCountryCode,
        countryRegion: countries[localStore?.countryCode ?? defaultCountryCode].region,
        agencyId: localStore?.agencyId,
        userAgencyIds: localStore?.userAgencyIds,
        agencies: localStore?.agencies,
        isLoggedIn: localStore?.isLoggedIn ?? false,
        isDev: import.meta.env.MODE === 'development' || import.meta.env.MODE === 'staging',
        isDemo: import.meta.env.MODE === 'demo',
        isTransactionEnabled: localStore?.isTransactionEnabled ?? false,
        enableViews: localStore?.enableViews ?? ({} as Agency['settings']['enableViews']),
    }

    const resetFilters = () => {
        Object.keys(sessionStorage)
            .filter((item) => item.startsWith('filters-storage'))
            .forEach((item) => sessionStorage.removeItem(item))
    }

    const [state, dispatchBase] = useReducer(appReducer, initialApp)

    const dispatch: Dispatch<Action> = (action) => {
        if (action.type === 'update' && action.payload.agencyId) {
            resetFilters()
        }

        dispatchBase(action)
    }

    const { data } = useGetAgency({ agencyId: localStore?.agencyId })

    const selectedLanguage = countries[state.countryCode].language

    if (selectedLanguage !== i18n.language) i18n.changeLanguage(selectedLanguage)

    useEffect(() => {
        state && setLocalStore(state)
    }, [state])

    useEffect(() => {
        if (mediaData?.media === state.mediaData?.media) return
        dispatch({
            type: 'update',
            payload: {
                mediaData,
            },
        })
    }, [mediaData?.media])

    useEffect(() => {
        // update agency group
        let agencyGroupId = data?.agency?.group || undefined
        if (!agencyGroupId && (state.agencies || []).every((a) => a.group === state.agencies?.[0].group)) {
            agencyGroupId = state.agencies?.[0].group
        }

        const payload: Partial<AppData> = {}
        if (agencyGroupId !== state.agencyGroupId) {
            payload['agencyGroupId'] = agencyGroupId
        }
        if (data?.agency !== state.agency) {
            payload['agency'] = data?.agency
        }
        if (Object.keys(payload).length) {
            dispatch({
                type: 'update',
                payload: {
                    ...payload,
                },
            })
        }
    }, [data?.agency?.group, data?.agency?.id])

    useEffect(() => {
        if (!Array.isArray(state.agencies)) return
        dispatch({
            type: 'update',
            payload: {
                isTransactionEnabled: canAgencyView(state.agencyId, state.agencies, 'sale_transactionBoard'),
            },
        })
    }, [state.agencies, state.agencyId])

    if (!state.jwt) {
        const token = getAccessToken()
        const refreshToken = getRefreshToken()
        if (token && refreshToken) {
            dispatch({
                type: 'update',
                payload: { jwt: { token, refreshToken }, isLoggedIn: true },
            })
        } else if (state.isLoggedIn) {
            dispatch({ type: 'update', payload: { isLoggedIn: false } })
        }
    } else if (state.jwt && !state.isLoggedIn) {
        dispatch({ type: 'update', payload: { isLoggedIn: true } })
    }

    return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>
}

function useApp() {
    const context = useContext(AppContext)

    if (context === undefined) {
        throw new Error('useApp must be used within a AppProvider')
    }

    return context
}

export { AppProvider, useApp }
