import { useQuery } from '@tanstack/react-query'
import type { AxiosError } from 'axios'

import axios from '@/axiosConfig'
import { getAccessToken } from '@/services/localStorageService'

import { agencyKeys } from '../agencyKeys'
import type { ResponseAgency } from '../types'

interface Props {
    agencyId?: string
}

export default function useGetAgency({ agencyId }: Props) {
    return useQuery<ResponseAgency, AxiosError<ErrorResponse>, ResponseAgency>({
        queryKey: agencyKeys.detail(agencyId),
        queryFn: () => axios.get(`/agency/${agencyId}`).then((res) => ({ ...res.data, agency: res.data.agency })),
        enabled: !!agencyId && !!getAccessToken(),
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 5, // 5 minutes
        retry: 5, // Will retry failed requests 5 times before displaying an error
        retryDelay: (attemptIndex) => Math.min(500 * 2 ** attemptIndex, 30000), // retryDelay is set to double (starting at 1000ms) with each attempt, but not exceed 30 seconds
    })
}
