import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { lazy, Suspense } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import Loading from '@/components/generics/Loading'
import { IS_DEV_ENV } from '@/constants'
import { AppProvider } from '@/contexts/AppContext'
import { GlobalSearchProvider } from '@/contexts/GlobalSearchContext'
import { MapInsightProvider } from '@/contexts/MapInsightContext'
import { NavbarProvider } from '@/contexts/NavbarContext'
import { TagsProvider } from '@/contexts/TagsContext'
import { UserProvider } from '@/contexts/UserContext'

const GlobalError = lazy(() => import('./pages/GlobalError'))
const TriangleBadge = lazy(() => import('@/components/generics/TriangleBadge'))
const Routing = lazy(() => import('@/components/navbar/Routing'))
const ErrorBoundary = lazy(() => import('@sentry/react').then((mod) => ({ default: mod.ErrorBoundary })))

import('./sentry')

const ONE_MINUTE = 1000 * 60 * 10

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: true,
            refetchOnMount: false,
            staleTime: ONE_MINUTE,
            retry: false,
        },
    },
})

const isDev = import.meta.env.MODE === 'development' || import.meta.env.MODE === 'staging'

export default function App(): JSX.Element {
    return (
        <BrowserRouter>
            <HelmetProvider>
                <QueryClientProvider client={queryClient}>
                    <Suspense
                        fallback={
                            <div className="flex h-full items-center justify-center">
                                <Loading withText />
                            </div>
                        }
                    >
                        <ErrorBoundary fallback={() => <GlobalError />}>
                            <AppProvider>
                                <NavbarProvider>
                                    <UserProvider>
                                        <GlobalSearchProvider>
                                            <MapInsightProvider>
                                                <TagsProvider>
                                                    <Helmet>
                                                        <title>Avest</title>
                                                        <link
                                                            rel="apple-touch-icon"
                                                            sizes="180x180"
                                                            href={`${isDev ? '/dev' : ''}/apple-touch-icon.png`}
                                                        />
                                                        <link
                                                            rel="icon"
                                                            type="image/png"
                                                            sizes="32x32"
                                                            href={`${isDev ? '/dev' : ''}/favicon-32x32.png`}
                                                        />
                                                        <link
                                                            rel="icon"
                                                            type="image/png"
                                                            sizes="16x16"
                                                            href={`${isDev ? '/dev' : ''}/favicon-16x16.png`}
                                                        />
                                                    </Helmet>
                                                    <ToastContainer position="bottom-right" theme="light" />

                                                    {IS_DEV_ENV && <TriangleBadge />}
                                                    <div className="h-full">
                                                        <Routing />
                                                    </div>
                                                </TagsProvider>
                                            </MapInsightProvider>
                                        </GlobalSearchProvider>
                                    </UserProvider>
                                </NavbarProvider>
                            </AppProvider>
                        </ErrorBoundary>
                    </Suspense>
                </QueryClientProvider>
            </HelmetProvider>
        </BrowserRouter>
    )
}
