import type { AreaType } from '../areas/types'
import type { Account, AccountServer, UserAgency, UserAgencyServer } from './types'
import { accessLevelsNum } from './types'

export const adaptServerUserAgency = (agency: UserAgencyServer): UserAgency => {
    return {
        isNegociator: agency.isNegociator,
        id: agency.id,
        accessLevel: typeof agency.accessLevel === 'number' ? accessLevelsNum[agency.accessLevel] : agency.accessLevel,
        email: agency.email,
        jobTitle: agency.jobTitle,
        saleShifts: agency.saleShifts,
        rentalShifts: agency.rentalShifts,
        acquisitionShifts: agency.acquisitionShifts,
        saleAreas: agency.saleAreas?.filter((area) =>
            (['postalCode', 'inseeCode', 'geometry'] as AreaType[]).includes(area.type)
        ),
        rentalAreas: agency.rentalAreas?.filter((area) =>
            (['postalCode', 'inseeCode', 'geometry'] as AreaType[]).includes(area.type)
        ),
        acquisitionAreas: agency.acquisitionAreas?.filter((area) =>
            (['postalCode', 'inseeCode', 'geometry'] as AreaType[]).includes(area.type)
        ),
        signature: agency.signature,
        areaColor: agency.areaColor,
        absences: agency.absences,
        allocationTransactionEligibilities: agency.allocationTransactionEligibilities,
    }
}

export const adaptServerAccount = (account: AccountServer): Account => {
    return {
        id: account.id,
        firstName: account.firstName,
        lastName: account.lastName,
        phone: account.phone,
        agencies:
            account.agencies?.reduce((acc, agency) => {
                return { ...acc, [agency.id]: adaptServerUserAgency(agency) }
            }, {} as Account['agencies']) || {},
        groupIds: account.groups || [],
        isRoot: account.isRoot,
        active: account.active,
        needsRefreshAfterUpdate: account?.needsRefreshAfterUpdate,
        settings: account.settings,
    }
}

export const adaptServerAccounts = (accounts: AccountServer[]): Account[] => accounts.map(adaptServerAccount)
