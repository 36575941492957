import { useQuery } from '@tanstack/react-query'
import type { AxiosError } from 'axios'

import axios from '@/axiosConfig'
import { useAgencyPermissions } from '@/components/navbar/RequireAgencyPermissions'
import { useApp } from '@/contexts/AppContext'

import { adaptServerRentalTag } from '../adapters'
import { tagsKeys } from '../tagsKeys'
import type { ResponseRentalTags, ResponseServerRentalTags } from '../types'
import { tagsTypesEnum } from '../types'

interface IParams {
    agencyId?: string | undefined
    agencyGroupId?: string | undefined
    disabled?: boolean
}

export default function useGetRentalTags({ agencyId, agencyGroupId, disabled }: IParams) {
    const {
        state: { isLoggedIn },
    } = useApp()

    const { isRentalEnabledFct } = useAgencyPermissions()
    const isRentalEnabled = isRentalEnabledFct(true)

    return useQuery<ResponseRentalTags, AxiosError<ErrorResponse>>({
        queryKey: tagsKeys.list(tagsTypesEnum.enum.rentalTag, agencyId),
        queryFn: () =>
            axios
                .get<ResponseServerRentalTags>('/rental-tags', {
                    params: {
                        ...(agencyId ? { agencies: agencyId } : {}),
                        ...(agencyGroupId ? { agencyGroup: agencyGroupId } : {}),
                    },
                })
                .then((res) => ({
                    success: res.data.success,
                    total: res.data.total,
                    tags: res.data.rentalTags.map(adaptServerRentalTag),
                })),
        enabled: !(disabled ?? false) && isLoggedIn && isRentalEnabled,
    })
}
