import type { ReactNode } from 'react'
import { createContext, useContext, useEffect, useReducer } from 'react'

import useLocalStorage from '@/hooks/useLocalStorage'

interface NavbarContextState {
    isNavOpen: boolean
    width: number
}

export enum NavbarSize {
    SM = 60,
    MD = 200,
    LG = 250,
    XL = 300,
}

type Action =
    | { type: 'update'; payload: Partial<NavbarContextState> }
    | { type: 'setIsNavOpen'; payload: boolean }
    | { type: 'setWidth'; payload: number }

const NavbarContext = createContext<{ state: NavbarContextState; dispatch: React.Dispatch<Action> } | undefined>(
    undefined
)
NavbarContext.displayName = 'Navbar'

function navbarReducer(state: NavbarContextState, action: Action): NavbarContextState {
    switch (action.type) {
        case 'update': {
            return { ...state, ...action.payload }
        }
        case 'setIsNavOpen': {
            const newNavbarWidth = state.isNavOpen
                ? NavbarSize.SM
                : state.width > NavbarSize.MD
                ? state.width
                : NavbarSize.LG

            return {
                ...state,
                width: newNavbarWidth,
                isNavOpen: !state.isNavOpen,
            }
        }
        case 'setWidth': {
            if (action.payload < NavbarSize.MD && action.payload >= NavbarSize.SM) {
                state.isNavOpen = false
                action.payload = NavbarSize.SM
            }
            if (action.payload >= NavbarSize.MD && !state.isNavOpen) state.isNavOpen = true
            return {
                ...state,
                width: action.payload,
                isNavOpen: state.isNavOpen,
            }
        }
        default: {
            return state
        }
    }
}

interface ProviderProps {
    children?: ReactNode
}
function NavbarProvider({ children }: ProviderProps) {
    const [localState, setLocalState] = useLocalStorage<NavbarContextState>('navbar', {
        width: NavbarSize.LG,
        isNavOpen: true,
    })
    const initialNavbar: NavbarContextState = {
        width: localState?.width ?? NavbarSize.LG,
        isNavOpen: localState?.isNavOpen ?? true,
    }
    const [state, dispatch] = useReducer(navbarReducer, initialNavbar)

    useEffect(() => {
        if (JSON.stringify(state) !== JSON.stringify(localState)) setLocalState(state)
    }, [state])

    const value = { state, dispatch }
    return <NavbarContext.Provider value={value}>{children}</NavbarContext.Provider>
}

function useNavbar() {
    const context = useContext(NavbarContext)
    if (context === undefined) {
        throw new Error('useNavbar must be used within a NavbarProvider')
    }
    return context
}

export { NavbarProvider, useNavbar }
