import 'rc-drawer/assets/index.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-phone-number-input/style.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import 'react-toastify/dist/ReactToastify.css'
import 'simplebar-react/dist/simplebar.min.css'
import './global.scss'
import './i18n'
import './index.css'
import './styles/mapbox-gl-draw.css'
import './styles/mapbox-gl.css'

import { createRoot } from 'react-dom/client'

import App from '@/App'

// refresh page on preload error
window.addEventListener('vite:preloadError', (e) => {
    // prevent default behavior of throwing an error
    e.preventDefault()

    window.location.reload()
})

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
root.render(
    // <StrictMode>
    <App />
    // </StrictMode>
)
