import { z } from 'zod'

export interface IServerTag {
    id: string
    name: string
    color: string
    agencyId: string
}

export const tagsTypesEnum = z.enum(['acquisitionTag', 'saleTag', 'rentalTag'])
export type ITagsTypes = z.infer<typeof tagsTypesEnum>

export type ITag = Pick<IServerTag, 'id' | 'name' | 'agencyId' | 'color'> & {
    __typename: ITagsTypes
}

export interface SaleTag extends ITag {
    __typename: 'saleTag'
}
export interface IRentalTag extends ITag {
    __typename: 'rentalTag'
}
export interface AcquisitionTag extends ITag {
    __typename: 'acquisitionTag'
}

export interface ResponseTag extends BaseResponse {
    tag: SaleTag | AcquisitionTag | IRentalTag
}
export interface ResponseTags extends BaseResponse {
    tags: Array<SaleTag | AcquisitionTag | IRentalTag>
    total: number
}

/* sales */
export interface ResponseServerSaleTag extends BaseResponse {
    saleTag: IServerTag
}
export interface ResponseServerSaleTags extends BaseResponse {
    saleTags: Array<IServerTag>
    total: number
}
export interface ResponseSaleTag extends BaseResponse {
    tag: SaleTag
}
export interface ResponseSaleTags extends BaseResponse {
    tags: SaleTag[]
    total: number
}

/* Acquisition */
export interface ResponseServerAcquisitionTag extends BaseResponse {
    acquisitionTag: IServerTag
}
export interface ResponseServerAcquisitionTags extends BaseResponse {
    acquisitionTags: Array<IServerTag>
    total: number
}
export interface ResponseAcquisitionTag extends BaseResponse {
    tag: AcquisitionTag
}
export interface ResponseAcquisitionTags extends BaseResponse {
    tags: Array<AcquisitionTag>
    total: number
}

/* Rentals */

export interface ResponseServerRentalTag extends BaseResponse {
    rentalTag: IServerTag
}
export interface ResponseServerRentalTags extends BaseResponse {
    rentalTags: Array<IServerTag>
    total: number
}

export interface ResponseRentalTag extends BaseResponse {
    tag: IRentalTag
}
export interface ResponseRentalTags extends BaseResponse {
    tags: Array<IRentalTag>
    total: number
}
