interface IAgencyKeys {
    scope: 'agencies'
    entity?: 'list' | 'groups' | 'detail' | 'allDetails' | 'senders' | 'agencyBounds'
    groupId?: string
    idOrToken?: string
    agencyId?: string
}

type IAgencyKeysMapper = {
    all: ReadonlyArray<IAgencyKeys>
    list: () => ReadonlyArray<IAgencyKeys>
    group: (groupId?: string) => ReadonlyArray<IAgencyKeys>
    details: () => ReadonlyArray<IAgencyKeys>
    detail: (idOrToken?: string) => ReadonlyArray<IAgencyKeys>
    allDetails: () => ReadonlyArray<IAgencyKeys>
    senders: () => ReadonlyArray<IAgencyKeys>
    sender: (agencyId?: string) => ReadonlyArray<IAgencyKeys>
    agencyBounds: (agencyId?: string) => ReadonlyArray<IAgencyKeys>
}

export const agencyKeys: IAgencyKeysMapper = {
    all: [{ scope: 'agencies' }] as const,
    list: () => [{ ...agencyKeys.all[0], entity: 'list' }] as const,
    group: (groupId?: string) => [{ ...agencyKeys.all[0], entity: 'groups', groupId }] as const,
    details: () => [{ ...agencyKeys.all[0], entity: 'detail' }] as const,
    detail: (idOrToken?: string) => [{ ...agencyKeys.details()[0], idOrToken }] as const,
    allDetails: () => [{ ...agencyKeys.all[0], entity: 'allDetails' }] as const,
    senders: () => [{ ...agencyKeys.all[0], entity: 'senders' }] as const,
    sender: (agencyId?: string) => [{ ...agencyKeys.senders()[0], agencyId }] as const,
    agencyBounds: (agencyId?: string) => [{ ...agencyKeys.all[0], entity: 'agencyBounds', agencyId }] as const,
}
