import type { User } from '@sentry/browser'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { AxiosError } from 'axios'

import axios from '@/axiosConfig'
import { deepCopy } from '@/lib/lodash'

import { accountKey } from '../accountKey'
import { adaptServerAccount } from '../adapters'
import type { AccountServer } from '../types'

type UpdateAccountEntry = Partial<
    Pick<AccountServer, 'firstName' | 'lastName' | 'phone' | 'needsRefreshAfterUpdate' | 'settings'>
>

export default function useUpdateAccount() {
    const queryClient = useQueryClient()
    return useMutation<
        BaseResponseT<{ user: User }>,
        AxiosError<{
            message: string
            status: number
        }>,
        UpdateAccountEntry
    >({
        mutationFn: (data) =>
            axios.patch<BaseResponseT<{ user: AccountServer }>>('/account', data).then((res) => ({
                ...res.data,
                user: adaptServerAccount(res.data.user),
            })),
        onSuccess: (data) => {
            queryClient.setQueryData<BaseResponseT<{ user: User }>>(
                accountKey.me,
                (prev) => {
                    if (!prev?.user || prev.user.id !== data.user.id) return prev
                    return { ...prev, user: deepCopy({ ...prev.user, ...data.user }) }
                },
                { updatedAt: Date.now() }
            )
        },
    })
}
