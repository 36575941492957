import { useCallback, useEffect, useState } from 'react'

import type { TBreakpointsKey } from './useGetMedia'

export type TBreakpointsKeyVal = Extract<TBreakpointsKey, 'sm' | 'md' | 'lg' | 'xl' | '2xl'>
export const breakpoints: { [key in TBreakpointsKeyVal]: number } = {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    '2xl': 1536,
} as const

const formatMatchMedia = (value: number): string => `(max-width: ${value - 1}px)`
const getMatches = (query: number): boolean => {
    // Prevents SSR issues
    if (typeof window === 'undefined') return false
    return window.matchMedia(formatMatchMedia(query)).matches
}

function useBreakpoints(value: TBreakpointsKeyVal | number): boolean {
    const [isMatches, setIsMatches] = useState<boolean>(false)
    const defValue = typeof value === 'string' ? breakpoints[value] : value

    const handleMediaQueryChange = useCallback(() => {
        setIsMatches(getMatches(defValue))
    }, [defValue])

    useEffect(() => {
        // use window.matchMedia for safari compatibility
        const matchMedia = window.matchMedia(formatMatchMedia(defValue))

        handleMediaQueryChange()

        matchMedia.addEventListener('change', handleMediaQueryChange)

        return () => {
            matchMedia.removeEventListener('change', handleMediaQueryChange)
        }
    }, [defValue, handleMediaQueryChange])

    return isMatches
}

export default useBreakpoints
