import ObjectId from 'bson-objectid'

export const setTokens = (tokenObj: { accessToken: string; refreshToken: string }) => {
    localStorage.setItem('access_token', tokenObj.accessToken)
    localStorage.setItem('refresh_token', tokenObj.refreshToken)
}

export const getAccessToken = () => {
    return localStorage.getItem('access_token')
}

export const getRefreshToken = () => {
    return localStorage.getItem('refresh_token')
}

export const clearTokens = () => {
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
}

export const getDeviceIdentifier = () => {
    const device = localStorage.getItem('device_identifier')

    if (!device) {
        const newDevice = ObjectId().toHexString()
        localStorage.setItem('device_identifier', newDevice)
        return newDevice
    }

    return device
}
