import type { User } from '@sentry/browser'
import { useQuery } from '@tanstack/react-query'
import type { AxiosError } from 'axios'

import axios from '@/axiosConfig'
import { getAccessToken } from '@/services/localStorageService'

import { accountKey } from '../accountKey'
import { adaptServerAccount } from '../adapters'
import type { AccountServer } from '../types'

export default function useGetAccount() {
    return useQuery<BaseResponseT<{ user: User }>, AxiosError<ErrorResponse>>({
        queryKey: accountKey.me,
        queryFn: () =>
            axios.get<BaseResponseT<{ user: AccountServer }>>('/account').then((res) => ({
                ...res.data,
                user: adaptServerAccount(res.data.user),
            })),
        enabled: !!getAccessToken(),
    })
}
