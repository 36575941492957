import { useQuery } from '@tanstack/react-query'
import type { AxiosError } from 'axios'

import axios from '@/axiosConfig'
import { getAccessToken } from '@/services/localStorageService'

import { agencyKeys } from '../agencyKeys'
import type { IResponseAgencies, ResponseAgencies } from '../types'

export default function useGetAgencies(props: { enabled?: boolean } | undefined = { enabled: true }) {
    return useQuery<ResponseAgencies, AxiosError<ErrorResponse>>({
        queryKey: agencyKeys.list(),
        queryFn: () =>
            axios.get<AxiosResponseAgencies>('/agencies').then((res) => ({ ...res.data, agencies: res.data.agencies })),
        enabled: props.enabled && !!getAccessToken(),
    })
}
interface AxiosResponseAgencies extends BaseResponse {
    agencies: IResponseAgencies[]
    total: number
}
