import { differenceInMinutes } from 'date-fns'
import { useState } from 'react'

type Callback<T> = (value: T | undefined) => T
type SetValueProps<T> = T | Callback<T>

type LocalStorageValue<T> = { data: T; lastSavedAt?: number }

export default function useLocalStorage<T>(
    key: string,
    initialValue?: T,
    lifeTimeInMinutes?: number
): [T | undefined, (v: SetValueProps<T>) => void] {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<T | undefined>(() => {
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key)
            // Parse stored json or if none return initialValue
            const value: LocalStorageValue<T> = item ? JSON.parse(item) : { data: initialValue }
            if (
                (value && !value.lastSavedAt) ||
                !lifeTimeInMinutes ||
                (lifeTimeInMinutes &&
                    value?.lastSavedAt &&
                    differenceInMinutes(new Date(), new Date(value.lastSavedAt)) <= lifeTimeInMinutes)
            ) {
                return value['data']
            }

            return initialValue
        } catch (error) {
            // If error also return initialValue
            console.error(error)
            return initialValue
        }
    })

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value: SetValueProps<T>) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore: T = value instanceof Function ? value(storedValue) : value
            // Save state
            setStoredValue(valueToStore)
            // Save to local storage
            window.localStorage.setItem(key, JSON.stringify({ data: valueToStore, lastSavedAt: new Date().getTime() }))
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.error(error)
        }
    }

    return [storedValue, setValue]
}
