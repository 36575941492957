import type { ITagsTypes } from './types'
import { tagsTypesEnum } from './types'

export const tagsKeys = {
    all: ['tags'] as const,
    typed: (type: ITagsTypes = tagsTypesEnum.enum.saleTag) => [...tagsKeys.all, type] as const,
    lists: (type?: ITagsTypes) => [...tagsKeys.typed(type), 'lists'] as const,
    list: (type?: ITagsTypes, agencyId?: string) => [...tagsKeys.lists(type), agencyId] as const,
    details: (type?: ITagsTypes) => [...tagsKeys.typed(type), 'detail'] as const,
    detail: (type?: ITagsTypes, idOrToken?: string) => [...tagsKeys.details(type), idOrToken] as const,
}
