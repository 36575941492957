import { useQuery } from '@tanstack/react-query'
import type { AxiosError } from 'axios'

import axios from '@/axiosConfig'
import { useAgencyPermissions } from '@/components/navbar/RequireAgencyPermissions'
import { useApp } from '@/contexts/AppContext'

import { adaptServerAcquisitionTag } from '../adapters'
import { tagsKeys } from '../tagsKeys'
import type { ResponseAcquisitionTags, ResponseServerAcquisitionTags } from '../types'
import { tagsTypesEnum } from '../types'

interface IParams {
    agencyId?: string | undefined
    agencyGroupId?: string | undefined
    disabled?: boolean
}

export default function useGetAcquisitionTags({ agencyId, agencyGroupId, disabled }: IParams) {
    const {
        state: { isLoggedIn },
    } = useApp()

    const { isAcquisitionEnabledFct } = useAgencyPermissions()
    const isAcquisitionEnabled = isAcquisitionEnabledFct(true)

    return useQuery<ResponseAcquisitionTags, AxiosError<ErrorResponse>>({
        queryKey: tagsKeys.list(tagsTypesEnum.enum.acquisitionTag, agencyId),
        queryFn: () =>
            axios
                .get<ResponseServerAcquisitionTags>('/acquisition-tags', {
                    params: {
                        ...(agencyId ? { agencies: agencyId } : {}),
                        ...(agencyGroupId ? { agencyGroup: agencyGroupId } : {}),
                    },
                })
                .then((res) => ({
                    success: res.data.success,
                    total: res.data.total,
                    tags: res.data.acquisitionTags.map(adaptServerAcquisitionTag),
                })),
        enabled: !(disabled ?? false) && isLoggedIn && isAcquisitionEnabled,
    })
}
