import type { ReactNode } from 'react'
import { createContext, useContext, useReducer } from 'react'

import type { SaleFilters, SaleSort } from '@/api/sale/types'

interface IMapInsightContextState {
    activeId?: string
    filters?: Partial<Omit<SaleFilters, 'property.addresses.location'>>
    sort?: SaleSort
    search?: string
}

type Action = { type: 'update'; payload: IMapInsightContextState }

const defaultValue: IMapInsightContextState = {
    activeId: 'false',
}

const MapInsightContext = createContext<
    { state: IMapInsightContextState; dispatch: React.Dispatch<Action> } | undefined
>(undefined)
MapInsightContext.displayName = 'MapInsight'

function mapinsightReducer(state: IMapInsightContextState, action: Action): IMapInsightContextState {
    switch (action.type) {
        case 'update': {
            return { ...state, ...action.payload }
        }
        default: {
            return state
        }
    }
}

interface BaseProviderProps {
    children: ReactNode
}
function MapInsightProvider({ children }: BaseProviderProps) {
    const [state, dispatch] = useReducer(mapinsightReducer, { ...defaultValue })

    const value = { state, dispatch }
    return <MapInsightContext.Provider value={value}>{children}</MapInsightContext.Provider>
}

function useMapInsight() {
    const context = useContext(MapInsightContext)
    if (context === undefined) {
        throw new Error('useMapInsight must be used within a MapInsightProvider')
    }
    return context
}

export { MapInsightProvider, useMapInsight }
