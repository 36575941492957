import { useEffect, useState } from 'react'
import { useMedia } from 'react-use'

import { breakpoints } from './useBreakpoints'

export type TBreakpointsKey = '2xl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs'
const breakpointKeys = ['xs', 'sm', 'md', 'lg', 'xl', '2xl']

export const isBreakpoint = (media: TBreakpointsKey | null, target: TBreakpointsKey): boolean => {
    if (media === null) return false
    return breakpointKeys.indexOf(media) < breakpointKeys.indexOf(target)
}

export interface TMediaData {
    media: TBreakpointsKey | null
    is2xl: boolean
    isXl: boolean
    isLg: boolean
    isMd: boolean
    isSm: boolean
    isXs: boolean
}
export const defaultMediaData: TMediaData = {
    media: null,
    is2xl: false,
    isXl: false,
    isLg: false,
    isMd: false,
    isSm: false,
    isXs: false,
}

export default function useGetMedia(): TMediaData | null {
    const [media, setMedia] = useState<TMediaData | null>()
    const is2xl = useMedia(`(min-width: ${breakpoints['2xl']}px)`)
    const isXl = useMedia(`(min-width: ${breakpoints['xl']}px)`)
    const isLg = useMedia(`(min-width: ${breakpoints['lg']}px)`)
    const isMd = useMedia(`(min-width: ${breakpoints['md']}px)`)
    const isSm = useMedia(`(min-width: ${breakpoints['sm']}px)`)
    const isXs = useMedia(`(max-width: ${breakpoints['sm'] - 1}px)`)

    useEffect(() => {
        const newMedia = is2xl ? '2xl' : isXl ? 'xl' : isLg ? 'lg' : isMd ? 'md' : isSm ? 'sm' : isXs ? 'xs' : null
        if (media === newMedia) return
        setMedia({
            media: newMedia,
            is2xl: isBreakpoint(newMedia, '2xl'),
            isXl: isBreakpoint(newMedia, 'xl'),
            isLg: isBreakpoint(newMedia, 'lg'),
            isMd: isBreakpoint(newMedia, 'md'),
            isSm: isBreakpoint(newMedia, 'sm'),
            isXs: isBreakpoint(newMedia, 'xs'),
        })
    }, [is2xl, isXl, isLg, isMd, isSm, isXs])

    return media ?? null
}
