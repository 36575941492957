import type { AcquisitionTag, IRentalTag, IServerTag, SaleTag } from './types'
import { tagsTypesEnum } from './types'

export const adaptServerSaleTag = (tag: IServerTag): SaleTag => ({
    ...tag,
    __typename: tagsTypesEnum.enum.saleTag,
})

export const adaptServerAcquisitionTag = (tag: IServerTag): AcquisitionTag => ({
    ...tag,
    __typename: tagsTypesEnum.enum.acquisitionTag,
})

export const adaptServerRentalTag = (tag: IServerTag): IRentalTag => ({
    ...tag,
    __typename: tagsTypesEnum.enum.rentalTag,
})
