import { setUser } from '@sentry/browser'
import type { ReactNode } from 'react'
import { createContext, useContext, useEffect, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useGetAccount, useUpdateAccount } from '@/api/account/hooks'
import type { Account } from '@/api/account/types'
import { useGetAgencies, useGetAgency } from '@/api/agency/hooks'
import type { Agency } from '@/api/agency/types'
import { ENV_WITH_ROOT_ALL_AGENCIES } from '@/constants'
import { deepCopy } from '@/lib/lodash'
import { objectsAreEqual } from '@/lib/utils'
import { clearSpecificLocalStorage } from '@/pages/CleanCache/utils'

import Flex from '../components/generics/Flex'
import Loading from '../components/generics/Loading'
import { useApp } from './AppContext'

export type User = Account

type Action = { type: 'update'; payload: Partial<User> } | { type: 'logout' }

const UserContext = createContext<{ state: User; dispatch: React.Dispatch<Action> } | undefined>(undefined)
UserContext.displayName = 'User'

function userReducer(state: User, action: Action): User {
    switch (action.type) {
        case 'update': {
            return { ...state, ...action.payload }
        }
        case 'logout': {
            return {} as User
        }
        default: {
            return state
        }
    }
}

interface ProviderProps {
    children?: ReactNode
}
function UserProvider({ children }: ProviderProps) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [state, dispatch] = useReducer(userReducer, {} as User)
    const [defaultUserAgencyId, setDefaultUserAgencyId] = useState<string>('')
    const [canSelectAllAgencies, setCanSelectAllAgencies] = useState<boolean>(false)
    const { state: stateApp, dispatch: dispatchApp } = useApp()
    const { data, isLoading, isFetched, isSuccess, isError, error, dataUpdatedAt } = useGetAccount()
    const { data: dataAgencies, refetch: refetchAgencies } = useGetAgencies()
    const { isError: isErrorAgency } = useGetAgency({ agencyId: stateApp.agencyId })
    const { mutate: updateAccount } = useUpdateAccount()

    const logout = () => {
        clearSpecificLocalStorage(['displaySettings'])
        toast.error(`${t('toast.error.login')}`)
        dispatch({ type: 'logout' })
        dispatchApp({ type: 'logout' })
    }

    useEffect(() => {
        if (!isFetched || !isSuccess || !data?.user || !dataAgencies?.agencies) return
        const user = data?.user
        const activeAgencyId = dataAgencies?.agencies.filter(({ isActive }) => isActive)?.map(({ id }) => id)
        const allAgencyIds = (dataAgencies?.agencies || []).map(({ id }) => id)
        const userAgencyIds = user?.isRoot
            ? allAgencyIds
            : Object.keys(user?.agencies || {}).filter((id) => (activeAgencyId ? activeAgencyId?.includes(id) : true))

        const newCanSelectAllAgencies = user.isRoot
            ? ENV_WITH_ROOT_ALL_AGENCIES && userAgencyIds.length > 1
            : userAgencyIds.length > 1
        setCanSelectAllAgencies(newCanSelectAllAgencies)

        const defaultUserAgencyId = newCanSelectAllAgencies ? '' : userAgencyIds[0]

        setDefaultUserAgencyId(defaultUserAgencyId)

        const newAgencyId = userAgencyIds.includes(stateApp.agencyId || '') ? stateApp.agencyId : defaultUserAgencyId
        if (newAgencyId === stateApp.agencyId) return
        dispatchApp({
            type: 'update',
            payload: {
                agencyId: newAgencyId,
                userAgencyIds,
            },
        })
    }, [dataAgencies?.agencies, data?.user])

    useEffect(() => {
        refetchAgencies()
    }, [data?.user?.id])

    useEffect(() => {
        if (!isErrorAgency || defaultUserAgencyId !== '' || (stateApp.userAgencyIds ?? []).length <= 1) return
        const newAgencyId = canSelectAllAgencies ? '' : stateApp.userAgencyIds?.[1]

        if (stateApp.agencyId === newAgencyId) return
        dispatchApp({
            type: 'update',
            payload: {
                agencyId: newAgencyId,
            },
        })
        navigate('/')
    }, [isErrorAgency])

    useEffect(() => {
        // on user fetched
        if (
            isFetched &&
            ((isError && (error?.status === 403 || typeof error.response?.data?.agency?.isActive !== 'undefined')) ||
                (isSuccess && data?.user && !data?.user.isRoot && Object.keys(data?.user.agencies).length === 0))
        ) {
            // logout on error
            // logout if user is not root and has no agencies
            logout()
            return
        }

        if (isFetched && isSuccess && !data?.user) return
    }, [isFetched])

    useEffect(() => {
        if (!data?.user.needsRefreshAfterUpdate) {
            return
        }

        const sessionStorageKeys = Object.keys(sessionStorage)
        //Delete all about filters in local storage
        sessionStorageKeys.filter((key) => key.includes('filters')).map((key) => sessionStorage.removeItem(key))

        updateAccount({ needsRefreshAfterUpdate: false })
    }, [data?.user?.needsRefreshAfterUpdate])

    useEffect(() => {
        if (!isFetched || !isError) return
        if (typeof error?.response?.data?.agency?.isActive === 'boolean' && !error?.response?.data?.agency?.isActive) {
            if (
                !!error?.response?.data?.agency?.inactiveReason &&
                error?.response?.data?.agency?.inactiveReason !== 'paymentLate'
            ) {
                navigate('/')
                toast.error(t('error.inactiveAgency.endedContract'))
            }
        }
    }, [error, stateApp.agencyId, state?.agencies])

    useEffect(() => {
        if (!Array.isArray(dataAgencies?.agencies) || !dataAgencies?.agencies.length) return

        const agencies = dataAgencies?.agencies.map((elem) => ({
            id: elem.id,
            group: elem.group,
            settings: elem.settings,
            name: elem.name,
        }))
        dispatchApp({
            type: 'update',
            payload: { agencies },
        })
    }, [dataAgencies?.agencies])

    useEffect(() => {
        if (!isFetched || !isSuccess || !data?.user) return
        const user = data.user as Pick<Account, 'id' | 'agencies'> & Partial<Omit<Account, 'id' | 'agencies'>>

        if (!objectsAreEqual(state, user)) {
            dispatch({ type: 'update', payload: deepCopy(user) })
        }

        setUser({
            id: user.id,
        })
    }, [data?.user, dataUpdatedAt])

    useEffect(() => {
        if (!dataAgencies?.agencies) return

        const agency = dataAgencies?.agencies.find(({ id }) => id === stateApp.agencyId)
        const groupAgencyIds = dataAgencies?.agencies
            ?.filter((elem) => elem.group === agency?.group)
            ?.map((elem) => elem.id)
            ?.join(',')

        const enableViews = stateApp.agencyId
            ? agency?.settings.enableViews
            : ({
                  sale_stk: dataAgencies?.agencies.reduce((acc, val) => acc || val.settings.enableViews.sale_stk, true),
                  sale_pqul: dataAgencies?.agencies.reduce(
                      (acc, val) => acc || val.settings.enableViews.sale_pqul,
                      true
                  ),
                  sale_arc: dataAgencies?.agencies.reduce((acc, val) => acc || val.settings.enableViews.sale_arc, true),
                  sale_trn: dataAgencies?.agencies.reduce((acc, val) => acc || val.settings.enableViews.sale_trn, true),
                  sale_trackingBoard: dataAgencies?.agencies.reduce(
                      (acc, val) => acc || val.settings.enableViews.sale_trackingBoard,
                      true
                  ),
                  sale_transactionBoard: dataAgencies?.agencies.reduce(
                      (acc, val) => acc || val.settings.enableViews.sale_transactionBoard,
                      true
                  ),
                  acquisition_aarc: dataAgencies?.agencies.reduce(
                      (acc, val) => acc || val.settings.enableViews.acquisition_aarc,
                      true
                  ),
                  acquisition_trackingBoard: dataAgencies?.agencies.reduce(
                      (acc, val) => acc || val.settings.enableViews.acquisition_trackingBoard,
                      true
                  ),
                  acquisition_table: dataAgencies?.agencies.reduce(
                      (acc, val) => acc || val.settings.enableViews.acquisition_table,
                      true
                  ),
                  rental_trackingBoard: dataAgencies?.agencies.reduce(
                      (acc, val) => acc || val.settings.enableViews.rental_trackingBoard,
                      true
                  ),
              } as Agency['settings']['enableViews'])
        const agencyGroupId = agency?.group || undefined

        const payload = {
            ...(agencyGroupId !== stateApp.agencyGroupId ? { agencyGroupId: agencyGroupId } : {}),
            ...(groupAgencyIds !== stateApp.groupAgencyIds ? { groupAgencyIds: groupAgencyIds } : {}),
            ...(!objectsAreEqual(enableViews, stateApp.enableViews) ? { enableViews } : {}),
        }
        if (Object.keys(payload).length === 0) return
        dispatchApp({
            type: 'update',
            payload,
        })
    }, [stateApp.agencyId, dataAgencies?.agencies])

    if (isLoading)
        return (
            <Flex className="h-screen w-screen" align="center" justify="center">
                <Loading withText />
            </Flex>
        )

    const value = { state, dispatch }

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

function useUser() {
    const context = useContext(UserContext)
    if (context === undefined) {
        throw new Error('useUser must be used within a UserProvider')
    }
    return context
}

export { UserProvider, useUser }
