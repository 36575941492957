import { createContext, useContext, useEffect, useReducer } from 'react'

import type { ITransactionTypes } from '@/api/account/types'
import useLocalStorage from '@/hooks/useLocalStorage'

import { useApp } from './AppContext'

interface Props {
    children: React.ReactNode
}

interface GlobalSearchState {
    recentlyViewedList: Array<{ id: string; __typename: ITransactionTypes }>
}

interface GlobalSearchStorage {
    [key: string]: Array<{ id: string; __typename: ITransactionTypes }>
}

type AddToRecents = {
    type: 'addTo'
    payload: { id: string; __typename: ITransactionTypes }
}
type SetRecents = { type: 'setRecents'; payload: Array<{ id: string; __typename: ITransactionTypes }> }

const GlobalSearchContext = createContext<
    | {
          state: GlobalSearchState
          dispatch: React.Dispatch<AddToRecents | SetRecents>
      }
    | undefined
>(undefined)
GlobalSearchContext.displayName = 'GlobalSearch'
function globalSearchReducer(state: GlobalSearchState, action: AddToRecents | SetRecents): GlobalSearchState {
    switch (action.type) {
        case 'addTo': {
            // check if the saleId is valid
            state.recentlyViewedList = state.recentlyViewedList.filter((item) => item.id?.length > 9)

            // if the saleId is not valid, return the state
            if (action.payload.id.length < 9) return state

            // if the list is empty, create a new one
            if (!state.recentlyViewedList || state.recentlyViewedList.length === 0) {
                const newRecent = [action.payload]
                return { recentlyViewedList: newRecent }
            }

            // if the saleId is already in the list, move it to the top
            if (state.recentlyViewedList.some((item) => item.id === action.payload.id)) {
                const newSearchOrder = state.recentlyViewedList.sort((item) => (item.id !== action.payload.id ? 0 : -1))

                return { recentlyViewedList: newSearchOrder }
            }

            // if the list is full, remove the last item
            if (state.recentlyViewedList.length === 9) {
                state.recentlyViewedList.pop()
            }

            state.recentlyViewedList.unshift(action.payload)

            return { recentlyViewedList: state.recentlyViewedList }
        }
        case 'setRecents': {
            return { recentlyViewedList: action.payload }
        }
        default: {
            return state
        }
    }
}

const GlobalSearchProvider = ({ children }: Props) => {
    const {
        state: { agencyId },
    } = useApp()
    const [localGlobalSearch, setLocalGlobalSearch] = useLocalStorage<GlobalSearchStorage>('globalSearch', {})
    const [state, dispatch] = useReducer(globalSearchReducer, {
        recentlyViewedList: localGlobalSearch?.[agencyId ?? ''] ?? [],
    })

    /** Update the local storage when the recentlyViewedList changes */
    useEffect(() => {
        setLocalGlobalSearch({
            ...localGlobalSearch,
            [agencyId ?? '']: state.recentlyViewedList,
        })
    }, [state.recentlyViewedList])

    return <GlobalSearchContext.Provider value={{ state, dispatch }}>{children}</GlobalSearchContext.Provider>
}

const useGlobalSearch = () => {
    const context = useContext(GlobalSearchContext)
    if (context === undefined) {
        throw new Error('useFilters must be used within a GlobalSearchContext')
    }
    return context
}

export { GlobalSearchProvider, useGlobalSearch }
