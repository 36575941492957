import { type ClassValue, clsx } from 'clsx'
import { isSameMinute } from 'date-fns'
import { twMerge } from 'tailwind-merge'

import { isDate } from '@/services/helpers'

import { deepCopy, isObject } from './lodash'

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}

export function arraysAreEqual<
    T extends string | number | Record<string, any> | Array<string | number | Record<string, any>> | any
>(array1: T[] | null | undefined, array2: T[] | null | undefined): boolean {
    if (!array1 && !array2) {
        return true
    }
    if (!Array.isArray(array1) || !Array.isArray(array2) || (array1 && !array2) || (array2 && !array1)) {
        return false
    }
    if (array1?.length !== array2?.length) {
        return false
    }

    const deepCopyArray1 = deepCopy(array1).sort()
    const deepCopyArray2 = deepCopy(array2).sort()

    for (let i = 0; i < (deepCopyArray1 as T[]).length; i++) {
        if ((!deepCopyArray1?.[i] && !!deepCopyArray2?.[i]) || (!!deepCopyArray1?.[i] && !deepCopyArray2?.[i])) {
            return false
        }
        if (Array.isArray((deepCopyArray1 as T[])[i])) {
            if (
                arraysAreEqual(
                    (deepCopyArray1 as T[])[i] as Array<string | number | Record<string, any>>,
                    (deepCopyArray2 as T[])[i] as Array<string | number | Record<string, any>>
                ) === false
            ) {
                return false
            }
        } else if (isObject((deepCopyArray1 as T[])[i])) {
            if (
                objectsAreEqual(
                    (deepCopyArray1 as T[])[i] as Record<string, any>,
                    (deepCopyArray2 as T[])[i] as Record<string, any>
                ) === false
            ) {
                return false
            }
        } else {
            if ((deepCopyArray1 as T[])[i] !== (deepCopyArray2 as T[])[i]) {
                return false
            }
        }
    }

    return true
}

export function objectsAreEqual<T extends Record<string, any>>(
    obj1: T | null | undefined,
    obj2: T | null | undefined
): boolean {
    if (!obj1 && !obj2) {
        return true
    }
    if ((obj1 && !obj2) || (obj2 && !obj1) || !isObject(obj1) || !isObject(obj2)) {
        return false
    }

    const array1 = obj1 ? Object.keys(obj1) : []
    const array2 = obj2 ? Object.keys(obj2) : []

    if (array1.length !== array2.length || arraysAreEqual([...array1].sort(), [...array2].sort()) === false) {
        return false
    }
    const deepCopyObj1 = deepCopy(obj1)
    const deepCopyObj2 = deepCopy(obj2)
    for (let i = 0; i < array1.length; i++) {
        const key = array1[i]
        const valueA = deepCopyObj1?.[key]
        const valueB = deepCopyObj2?.[key]
        if (Array.isArray(valueA) || Array.isArray(valueB)) {
            if (!arraysAreEqual(valueA, valueB)) {
                return false
            }
        } else if (isDate(valueA) || isDate(valueB)) {
            if (!isSameMinute(new Date(valueA), new Date(valueB))) {
                return false
            }
        } else if (isObject(valueA) || isObject(valueB)) {
            if (!objectsAreEqual(valueA, valueB)) {
                return false
            }
        } else if (Number.isInteger(valueA) || Number.isInteger(valueB)) {
            if (parseInt(valueA) !== parseInt(valueB)) {
                return false
            }
        } else if (valueA !== valueB) {
            return false
        }
    }

    return true
}

export const removeSpaceFromString = (string: string) => string.replace(/\s/g, '')

function normalizeString(string: string): string {
    return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export const stringCompare = (string1: string, string2: string): boolean =>
    normalizeString(string1).toLowerCase() === normalizeString(string2).toLowerCase()

export const stringCompareIncludes = (string1: string, string2: string): boolean =>
    normalizeString(string1).toLowerCase().includes(normalizeString(string2).toLowerCase())

export const isEqualObjectKeys = (
    prev: { [key: string]: any } | undefined,
    next: { [key: string]: any } | undefined,
    keys?: string[] | undefined
) => {
    const prevArray = Array.from(Object.keys(prev || {}))
    const nextArray = Array.from(Object.keys(next || {}))
    return (
        prevArray.length === nextArray.length &&
        arraysAreEqual(prevArray, nextArray) &&
        (!Array.isArray(keys) ||
            !keys?.length ||
            prevArray.every((key) => keys?.every((k) => prev?.[key]?.[k] === next?.[key]?.[k])))
    )
}

export const addPrefix = (prefix: string, value: string) => `${prefix}${value}`

export const getCircularReplacer = (): ((key: string, value: any) => any) => {
    const seen = new WeakSet<any>()
    return (key: string, value: any): any => {
        if (typeof value === 'object' && value !== null) {
            if (seen.has(value)) {
                // If circular reference is detected, return undefined
                return undefined
            }
            seen.add(value)
        }
        // Return the value
        return value
    }
}
