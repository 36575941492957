import { setUser } from '@sentry/browser'
import type { AxiosRequestConfig } from 'axios'
import axios, { AxiosError } from 'axios'

import { isDemo } from '@/constants'

import {
    clearTokens,
    getAccessToken,
    getDeviceIdentifier,
    getRefreshToken,
    setTokens,
} from './services/localStorageService'

const version = isDemo ? 'v2' : 'v1'

const serverBaseUrl = /^https?:\/\/.+$/.test(import.meta.env.VITE_API_BASE_URL)
    ? `${import.meta.env.VITE_API_BASE_URL}/${version}`
    : `https://${import.meta.env.VITE_API_BASE_URL}/${version}`

console.log(serverBaseUrl)

const instance = axios.create({
    baseURL: serverBaseUrl,
    withCredentials: false,
})

instance.interceptors.request.use(
    (config) => {
        const accessToken = getAccessToken()
        const deviceIdentifier = getDeviceIdentifier()
        if (accessToken) {
            config.headers.set('Authorization', `Bearer ${accessToken}`)
        }
        config.headers.set('Avest-Device-Identifier', deviceIdentifier)
        return config
    },
    (error) => {
        Promise.reject(error)
    }
)

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
    _retry?: boolean
}

const refreshToken = async (): Promise<string | null> => {
    const deviceIdentifier = getDeviceIdentifier()
    const refreshToken = getRefreshToken()

    if (!refreshToken || !deviceIdentifier) return getAccessToken()

    const res = await axios.post(
        '/account/refresh-token',
        { refreshToken: refreshToken },
        { baseURL: serverBaseUrl, headers: { 'Avest-Device-Identifier': deviceIdentifier } }
    )
    const newToken = res.data.user.token
    const newRefreshToken = res.data.user.refreshToken

    // update the original request's headers with the new token
    setTokens({ accessToken: newToken, refreshToken: newRefreshToken })
    // return the new token
    return newToken
}

const logout = async () => {
    console.error('logout as token error')
    clearTokens()
    window.location.replace('/logout')
    setUser(null)
}

instance.interceptors.response.use(
    (response) => {
        return response
    },
    async (error: AxiosError) => {
        const originalRequest: CustomAxiosRequestConfig = error.config as CustomAxiosRequestConfig
        if (error instanceof AxiosError && !originalRequest._retry && error?.response?.status === 401) {
            try {
                originalRequest._retry = true
                // Attempt to refresh the token
                const newToken = await refreshToken()
                if (!newToken) {
                    console.error('logout as no token')
                    logout()
                    return Promise.reject(new Error('no token, logout user'))
                }
                axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
            } catch (error) {
                if (error instanceof AxiosError && error?.response?.status === 401) {
                    console.error('logout as 401')
                    logout()
                }
            }
            return instance(originalRequest)
        }
        return Promise.reject(error)
    }
)

export default instance
