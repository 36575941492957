import type { ReactNode } from 'react'
import { createContext, useContext, useEffect, useReducer } from 'react'

import useGetAcquisitionTags from '@/api/tags/hooks/useGetAcquisitionTags'
import useGetRentalTags from '@/api/tags/hooks/useGetRentalTags'
import useGetSaleTags from '@/api/tags/hooks/useGetSaleTags'
import type { AcquisitionTag, IRentalTag, SaleTag } from '@/api/tags/types'
import useLocalStorage from '@/hooks/useLocalStorage'

import { useApp } from './AppContext'

interface TagsState {
    saleTags: SaleTag[]
    acquisitionTag: AcquisitionTag[]
    rentalTags: IRentalTag[]
    isCardTagsOpen: boolean
}

type Action =
    | { type: 'setSaleTags'; payload: SaleTag[] }
    | { type: 'setAcquisitionTags'; payload: AcquisitionTag[] }
    | { type: 'setRentalTags'; payload: IRentalTag[] }
    | { type: 'setCardTagsOpen'; payload: boolean }

const TagsContext = createContext<{ state: TagsState; dispatch: React.Dispatch<Action> } | undefined>(undefined)
TagsContext.displayName = 'Tags'

function tagsReducer(state: TagsState, action: Action): TagsState {
    switch (action.type) {
        case 'setSaleTags': {
            return { ...state, saleTags: action.payload }
        }
        case 'setAcquisitionTags': {
            return { ...state, acquisitionTag: action.payload }
        }
        case 'setRentalTags': {
            return { ...state, rentalTags: action.payload }
        }
        case 'setCardTagsOpen': {
            return { ...state, isCardTagsOpen: action.payload }
        }
        default: {
            return state
        }
    }
}

interface ProviderProps {
    children: ReactNode
}
function TagsProvider({ children }: ProviderProps) {
    const [savedIsCardTagsOpen, setSavedIsCardTagsOpen] = useLocalStorage<boolean>('isCardTagsOpen', false)
    const initialApp: TagsState = {
        saleTags: [],
        acquisitionTag: [],
        rentalTags: [],
        isCardTagsOpen: savedIsCardTagsOpen ?? false,
    }
    const {
        state: { agencyId, agencyGroupId },
    } = useApp()
    const [state, dispatch] = useReducer(tagsReducer, initialApp)
    const { data: dataSaleTags } = useGetSaleTags({ agencyId })
    const { data: dataAcquisitionTags } = useGetAcquisitionTags({ agencyGroupId })
    const { data: dataRentalTags } = useGetRentalTags({ agencyId })

    useEffect(() => {
        setSavedIsCardTagsOpen(state.isCardTagsOpen)
    }, [state.isCardTagsOpen])

    useEffect(() => {
        if (!dataSaleTags) return
        dispatch({ type: 'setSaleTags', payload: dataSaleTags.tags })
    }, [dataSaleTags?.tags])

    useEffect(() => {
        if (!dataAcquisitionTags) return
        dispatch({ type: 'setAcquisitionTags', payload: dataAcquisitionTags.tags })
    }, [dataAcquisitionTags?.tags])

    useEffect(() => {
        if (!dataRentalTags) return
        dispatch({ type: 'setRentalTags', payload: dataRentalTags.tags })
    }, [dataRentalTags?.tags])

    const value = { state, dispatch }
    return <TagsContext.Provider value={value}>{children}</TagsContext.Provider>
}

function useTags() {
    const context = useContext(TagsContext)
    if (context === undefined) {
        throw new Error('useTags must be used within a TagsProvider')
    }
    return context
}

export { TagsProvider, useTags }
